<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import {
    saveGridState,
    loadGridState,
} from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import c3api from '@/c3api';
import { yearMonthDayFormat } from '@/utils/dateUtils';
import { onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';
import useWarehouses from "@/hooks/useWarehouses";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const { getCachedWarehouseFilters } = useWarehouses();

const selectedDate = ref();
const loadCount = ref(0);
const loadingUpcomingDate = ref(false);
const loadingSkuTotals = ref(false);
const toast = useToast();
const upcoming = ref([]);
const skuTotals = ref([]);

const themeStore = useThemeStore();
const SCHEDULE_EXTERNAL_FILTERS_KEY = "schedule-external-filter";
const GRID_SAVE_KEY = "prep-schedule";

let gridApi;

const props = defineProps({
    selectedDate: Date,
    callBack: Function
})

onMounted(() => {
    selectedDate.value = yearMonthDayFormat(props.selectedDate, "-")
    fetchSkuTotals();
    fetchUpcomingPreps();
})


const fetchSkuTotals = async () => {
    loadingSkuTotals.value = true;
    const urlParams = {
        date: yearMonthDayFormat(selectedDate.value)
    }
    try {
        const response = await c3api.get(`/outbound_orders/preps/sku_stats`, {params: urlParams});
        skuTotals.value = response.data.data;
        loadingSkuTotals.value = false;
    } catch (error) {
        loadingSkuTotals.value = false;
        toast.error(error.response.data.data.join(". "));
    }
}

const fetchUpcomingPreps = async () => {
    loadingUpcomingDate.value = true;
    let whIds = getCachedWarehouseFilters(SCHEDULE_EXTERNAL_FILTERS_KEY);
    if (whIds.length < 1) whIds = [1];
    const urlParams = {
        warehouse_ids: whIds
    }
    try {
        const response = await c3api.get(`/outbound_orders/preps/stats_by_day`, {params: urlParams});
        console.log("Response Data:", response.data);
        upcoming.value = response.data.data;
        loadingUpcomingDate.value = false;
    } catch (error) {
        loadingUpcomingDate.value = false;
        toast.error(error.response.data.data.join(". "));
    }
}

const selectUpcomingDate = (date) => {
    selectedDate.value = date;
    props.callBack(selectedDate.value, 'date');
    // gridApi?.refreshServerSide();
    // fetchSkuTotals();
}

const upcomingFormat = (newDate) => {
    const date = new Date(newDate);
    const day = date.toLocaleString('en-US', { weekday: 'short' });
    const month = date.toLocaleString('en-US', { month: 'short' });
    const dayNumber = date.getDate();
    return `<span>${day}</span> <span>${month} ${dayNumber}</span>`;
}

const onGridReady = (params) => {
    gridApi = params.api;
    props.callBack(gridApi);
    loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
};

const onStateUpdated = function (params) {
    console.log("State Updated:", params);
    saveGridState(GRID_SAVE_KEY, gridApi);
};

const defaultColDef = {
    sortable: false,
    suppressHeaderMenuButton: true,
};

const columnDefs = [
    { headerName: "#", cellDataType: "text",valueGetter: "node.rowIndex + 1", filter: false, floatingFilter: false, sortable:false},
    { field: "warehouse.nickname", headerName: "Warehouse", colId: "warehouse", cellDataType: "text" },
    { field: "po_number", headerName: "PO Number", colId: "po_number", cellDataType: "text" },
    { field: "ship_zip", headerName: "Zip Code", colId: "zip_code", cellDataType: "text", valueGetter: (p) => p.data.ship_state + ", " + p.data.ship_zip},
    { field: "prep_on", headerName: "Pickup On", colId: "prep_on", cellDataType: "text" },
    { field: "total_cases", headerName: "Total CS", colId: "total_cases", cellDataType: "text" },
    { field: "total_pallets", headerName: "Total PLT", colId: "total_pallets", cellDataType: "text" },
    { field: "order_status.name", headerName: "Status", colId: "status", cellDataType: "text" },
    { field: "notes", headerName: "Notes", colId: "notes", cellDataType: "text" },
];

const buildQueryUrl = function (params) {

    let whIds = getCachedWarehouseFilters(SCHEDULE_EXTERNAL_FILTERS_KEY);
    if (whIds.length < 1) whIds = [1];

    const urlParams = {
        sortModel: params.request.sortModel,
        startRow: params.request.startRow,
        endRow: params.request.endRow,
        sort: [
            {
            sort: "asc",
            colId: "name",
            },
        ],
        limit: 500,
    };
    const filterParams = {
        prep_on: {
            filter: yearMonthDayFormat(selectedDate.value, '-'),
            type: 'equals'
        },
        warehouse_id: { 
            type: "set", 
            filter: whIds
        },
    };
    urlParams.filter = filterParams;
    return urlParams;
};

const connectDatasource = {
    async getRows(params) {
        loadCount.value++;
        gridApi.showLoadingOverlay();
        const urlParams = buildQueryUrl(params);
            try {
            const response = await c3api.get("/outbound_orders/preps", { params: urlParams });
            const data = response.data;
            console.log(data, "prep response data");
            params.success({
            rowData: data.data,
            rowCount: data.total_count,
            });
        } catch (err) {
            console.error(err);
            toast.error(err.response.data.data.join(". "));
            params.fail();
        } finally {
            loadCount.value--;
            if (loadCount.value <= 0) {
            gridApi.hideOverlay();
            }
        }
    },                      
    maxConcurrentDatasourceRequests: 1,
};

const gridOptions = {
    rowModelType: "serverSide",
    serverSideDatasource: connectDatasource,
    columnDefs: columnDefs,
    cacheBlockSize: 100,
    blockLoadDebounceMillis: 500,
    autoSizeStrategy: {
        type: "fitGridWidth",
        defaultMinWidth: 100,
    },
    getRowId: (params) => {
        return params.data.id;
    }
};
</script>

<template>
    <div class="upcoming-header">
        <div v-if="upcoming.length == 0" class="p-4">{{ loadingUpcomingDate ? "Loading..." : "Nothing Scheduled"}}</div>
        <div :class="{ date: true, active: d.date == selectedDate }"
            @click="selectUpcomingDate(d.date)"
            v-for="d in upcoming"
            :key="d.date" 
            >
            <div class="d" v-html="upcomingFormat(d.date)"></div>
            <div class="counts">
                <div class="ct">
                    <v-icon icon="mdi-clipboard-text" style="font-size: 0.75rem;padding:0;margin:0;"></v-icon>
                    <span style="font-size:75%">{{ d.order_count }}</span>
                </div>
                <div class="plts">
                    <v-icon icon="mdi-shipping-pallet" style="font-size: 0.75rem;padding:0;margin:0;"></v-icon>
                    <span style="font-size:75%">{{ d.total_pallets }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 h-100">
        <AgGridVue style="width: 100%; height: 100%" :class="themeStore.agTheme" :columnDefs="columnDefs"
            :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady"
            @state-updated="onStateUpdated"></AgGridVue>

        <div class="sku-container" style="width: 450px; border-left: 5px solid #333; z-index: 0">
            <table class="table sku-table" style="border-left: 0px solid #222;">
                <thead>
                    <tr>
                        <th colspan="2" align="start" class="bg-surface-light">SKU Totals</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="skuTotals.length == 0" class="p-4">
                        <td>{{ loadingSkuTotals ? "Loading..." : "Not Available"}}</td>
                    </tr>
                    <tr v-for="sku in skuTotals">
                        <td>
                            {{ sku.sku }}<br />
                            <small>{{ sku.name }}</small>
                        </td>
                        <td style="font-size: 22px;">{{ sku.total }} <small>{{ sku.uom }}</small></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</template>

<style lang="scss" scoped>

.upcoming-header {
    display: flex;
    justify-content: flex-start;
    border-bottom: #b3b3b3 0px solid;
    width: 100%;
    min-height: 75px;
    overflow-x: auto;
    overflow-y: hidden;
}

.upcoming-header .date {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: 75px;
    min-width: fit-content;
    border-right: #b3b3b3 1px solid;
    justify-content: space-between;
    cursor: pointer;
}

.upcoming-header .date.active {
    background-color: #adadade1;
}

.upcoming-header .counts {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-weight: 300;
}

.upcoming-header .date .d {
    display: flex;
    justify-content: space-between;
    font-variant: small-caps;
}

.upcoming-header .date .ct {
    text-align: left;
}

.upcoming-header .date .plts {
    text-align: right;
}

.sku-container {
    height: 100%;
    overflow-y: auto;
}

.sku-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
}

.sku-table td,
.sku-table th{
    border: 0;
    vertical-align: middle;
    font-weight: 300;
    font-weight: normal;
}
table thead th,
table tbody td {
    padding: 10px;
}
table thead th {
    position: sticky;
    top: 0;
}
.table thead tr th {
    border-bottom-width: 0;
}

.sku-table tbody tr td {
    border-bottom: 1px solid #B5B5B5 !important;
}
.sku-table thead tr th {
    border-top: 1px solid #3E4449 !important;
}
.sku-table tr {
    color: #fff;
    background-color: #343A3F; 
}

.sku-table tbody tr:nth-of-type(odd) {
    background-color: #3E4449; 
}

</style>