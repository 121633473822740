<script setup>

import { ref, onBeforeMount, watch } from "vue";
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
import useSchedule from "@/hooks/useSchedule.js";

const { updateAppt } = useSchedule();

const props = defineProps({
    currentAppt: Object,
    doors: Array,
    callback: Function
});

onBeforeMount(() => {
    console.log(props.currentAppt);
});

const dialog = ref(true);
const currentAppt = ref(props.currentAppt);

watch(() => props.currentAppt, (newValue) => {
    currentAppt.value = newValue;
});

const saveAppt = async () => {

    const updateData = {
        checked_in_at: currentAppt.value.checked_in_at,
        checked_out_at: currentAppt.value.checked_out_at,
        door_id: currentAppt.value.door_id,
        notes: currentAppt.value.notes
    };

    await updateAppt(currentAppt.value.id, updateData);
    props.callback();
}

function setTimeToNow(inOut) {
    const date = new Date();
    const currTime = date.getHours().toString().padStart(2, '0')
        + ":" + date.getMinutes().toString().padStart(2, '0');
    console.log(currTime);

    if (inOut == 1) {
        props.currentAppt.checked_in_at = currTime;
    } else {
        props.currentAppt.checked_out_at = currTime;
    }
}

</script>

<template>
    <v-dialog v-model="dialog" max-width="500" persistent>
        <v-card v-if="props.currentAppt.id" elevation="0" flat class="w-100 bg-opacity-50">
            <v-card-title class="bg-blue">
                Driver Check-in
            </v-card-title>
            <v-card-text>
                <v-table>
                    <tr class="table-row">
                        <td class="border-0 align-middle font-weight-bold">Carrier</td>
                        <td class="border-0 align-middle">
                            <span v-if="props.currentAppt.carrier">{{ props.currentAppt.carrier.name }}</span>
                            <span v-else>No Carrier Listed</span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="border-0 align-middle font-weight-bold">Shipment</td>
                        <td class="border-0 align-middle">
                            {{ props.currentAppt.order?.client?.name }} &bull;
                            <a :href="props.currentAppt?.order_link">
                                {{ props.currentAppt.order?.po_number }}
                            </a>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="border-0 align-middle font-weight-bold">
                            Check In <i class="far fa-clock"></i>
                        </td>
                        <td class="border-0 align-middle" style="height: 70px;">
                            <span v-if="props.currentAppt.checked_in_at">
                                <VueTimepicker v-model="currentAppt.checked_in_at">
                                </VueTimepicker>
                            </span>
                            <span v-else>
                                <v-btn color="primary" size="x-small" @click="setTimeToNow(1)">
                                    Set to Now
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="border-0 align-middle font-weight-bold" style="width: 130px;">
                            Check Out
                        </td>
                        <td style="height: 70px;">
                            <span v-if="props.currentAppt.checked_out_at">
                                <VueTimepicker v-model="currentAppt.checked_out_at">
                                </VueTimepicker>
                            </span>
                            <span v-else>
                                <v-btn size="x-small" color="primary" :disabled="!currentAppt.checked_in_at"
                                    @click="setTimeToNow(2)">
                                    Set to Now
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="border-0 align-middle font-weight-bold">Dock Door</td>
                        <td class="border-0 align-middle">
                            <v-btn class="mr-1 mb-1" size="x-small"
                                style="padding: 3px; font-size: 12px; line-height: 13px; height: 20px;"
                                :color="[props.currentAppt.door_id == null ? 'secondary' : 'default']"
                                @click="currentAppt.door_id = null">
                                TBD
                            </v-btn>
                            <v-btn v-for="door in props.doors" class="mr-1 mb-1" size="x-small"
                                style="padding: 3px; font-size: 12px; line-height: 13px; height: 20px;"
                                :color="[door.id == props.currentAppt.door_id ? 'secondary' : 'default']"
                                @click="currentAppt.door_id = door.id">
                                {{ door.nickname }}
                            </v-btn>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="border-0 align-middle font-weight-bold">Bill of Lading</td>
                        <td class="border-0 text-left">
                            <a v-if="props.currentAppt.bol_link" target="_blank" :href="props.currentAppt.bol_link">
                                {{ props.currentAppt.bol_filename }}
                                <i class="fa fa-external-link-alt"></i>
                            </a>
                            <span class="text-muted" v-else>Not on File</span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="border-0 text-left" colspan="2">
                            <label class="font-weight-bold">Notes</label>
                            <v-textarea class="form-control" v-model="currentAppt.notes" rows="3"></v-textarea>
                        </td>
                    </tr>
                </v-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="flat" @click="callback()" dark elevation="0">Cancel</v-btn>
                <v-btn color="primary" variant="flat" @click="saveAppt">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
table {
    border-spacing: 0 10px;
    /* Horizontal and vertical spacing */
}

th,
td {
    padding: 8px;
}
</style>
