<script setup>
import Staged from '@/components/schedule/Staged.vue';
import Warehouses from '@/components/common/Warehouses.vue';
import PrepScheduleTab from '@/components/schedule/PrepScheduleTab.vue';
import { ref, computed, watch, onMounted } from 'vue';
import { setPageTitle } from '@/utils/pageTitle';
import DocksSchedule from '@/components/schedule/DocksSchedule.vue';

const loading = ref(false);
const settings = ref({})
const showMenu = ref(false);
const SCHEDULE_EXTERNAL_FILTERS_KEY = "schedule-external-filter";
const LS_SETTINGS_KEY = "schedule-settings";
const prepGridApi = ref(null);
const updateScheduleDocks = ref(0);

const defaultSettings = {
    view: 'docks',
    selectedDate: new Date()
}

const changeView = (newView) => {
    settings.value.view = newView;
    if (settings.value.view == 'docks') { console.log('docks') }
    if (settings.value.view == 'prep') { console.log('preps') }
    if (settings.value.view == 'staged') { console.log('staged') }
}

onMounted(() => {
    // Load the last selected date.
    settings.value = JSON.parse(window.localStorage.getItem(LS_SETTINGS_KEY)) || defaultSettings;
    // changed the store date string to date object
    if (settings.value.selectedDate) {
        settings.value.selectedDate = new Date(settings.value.selectedDate);
    }
    setPageTitle("Schedule");
})

const formatDate = (date) => {
    if (date) {

        const day = date.toLocaleString('en-US', { weekday: 'long' });
        const month = date.toLocaleString('en-US', { month: 'long' });
        const dayNumber = date.getDate();
        let daySuffix = "th";
        if (dayNumber == 1 || dayNumber == 21 || dayNumber == 31) {
            daySuffix = "st";
        } else if (dayNumber == 2 || dayNumber == 22) {
            daySuffix = "nd";
        } else if (dayNumber == 3 || dayNumber == 23) {
            daySuffix = "rd";
        }
        return `${day}, ${month} ${dayNumber}${daySuffix}`;
    }
}

const formattedDate = computed(() => {
    return formatDate(new Date(settings.value.selectedDate));
})

const warehouseCallback = () => {

    if (settings.value.view == 'docks') {
        updateScheduleDocks.value++;
    }

    if (settings.value.view == 'prep') { 
        prepGridApi.value?.refreshServerSide();
    }

    if (settings.value.view == 'staged') { 
    }
}

const prepCallback = (data, type = 'gridApi') => {
    if (type == 'gridApi') {
        prepGridApi.value = data;
    }
    if (type == 'date') {
        settings.value.selectedDate = new Date(data);
    }
}

watch(settings, () => {
    window.localStorage.setItem(LS_SETTINGS_KEY, JSON.stringify(settings.value));
}, { deep: true })

</script>

<template>
    <Warehouses :call-back="warehouseCallback" :parent-filter="SCHEDULE_EXTERNAL_FILTERS_KEY" />

    <div class="top-menu">
        <div class="date">
            <v-menu v-model="showMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                max-width="290px" min-width="290px">
                <template v-slot:activator="{ props }">
                    <v-btn class="h-100" v-bind="props" x-large :loading="loading" variant="text">
                        {{ formattedDate }}
                    </v-btn>
                </template>
                <v-date-picker v-model="settings.selectedDate" no-title
                    @update:modelValue="() => { showMenu = false }"></v-date-picker>
            </v-menu>
        </div>
        <div class="menu-buttons">
            <button @click="changeView('docks')" :class="[settings.view == 'docks' ? 'active' : '']">Dock
                Schedule</button>
            <button @click="changeView('prep')" :class="[settings.view == 'prep' ? 'active' : '']">Prep
                Schedule</button>
            <button @click="changeView('staged')" :class="[settings.view == 'staged' ? 'active' : '']">Staged</button>
            <div class="empty"></div>
        </div>
    </div>

    <div class="d-flex" v-if="settings.view == 'docks'">
        <DocksSchedule :date="settings.selectedDate" :key="settings.selectedDate + ' '+ updateScheduleDocks"/>
    </div>

    <div class="table-fixed-head" v-if="settings.view == 'prep'">
        <PrepScheduleTab :key="settings.selectedDate" :selectedDate="settings.selectedDate" :callBack="prepCallback" />
    </div>

    <div v-if="settings.view == 'staged'">
        <Staged />
    </div>
</template>

<style scoped>
.top-menu {
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
}

.top-menu .date {
    margin: 0;
    padding: 0;
    height: 50px;
    width: 187px;
    font-size: 22px;
    border-bottom: 1px solid #b3b3b3;
    border-top: 1px solid #b3b3b3;
}

.top-menu .menu-buttons {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    border-bottom: 1px solid transparent;
}

.top-menu .menu-buttons button {
    height: 50px;
    color: #8f8f8f;
    text-align: center;
    flex: 0 0 auto;
    min-width: 255px;
    border: 0;
    border-left: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    border-top: 1px solid #b3b3b3;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    outline: none;
    font-weight: 300;
}

.top-menu .menu-buttons .empty {
    flex: 1;
    height: 50px;
    border-top: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    border-left: 1px solid #b3b3b3;
}

.top-menu .menu-buttons button:hover {
    font-weight: normal;
    text-decoration: none;
    color: #333;
    background: rgb(255, 255, 255, 0.4);
}

.top-menu .menu-buttons button.active {
    font-weight: normal;
    text-decoration: none;
    color: #333333;
    background: #efefef;
    border-bottom: 1px solid #ffffff !important;
}

.table-fixed-head {
    overflow: auto;
    height: calc(100vh - 60px);
}

.table-fixed-head table thead th {
    position: sticky;
    top: 0;
}

[v-cloak] {
    display: none !important;
}

.orders-table tr.pending td,
.stats-item .pending {
    background: #9e9e9e;
}

.orders-table tr.active td,
.stats-item .active {
    background: #1976d2;
}

.orders-table tr.complete td,
.stats-item .complete {
    background: #4caf50;
}

.orders-table tr.late td,
.stats-item .late {
    background: #f44336;
}

.orders-table tr td a {
    color: #333333;
}

.ltl .appt {
    max-width: 555px;
}

.bg-light-green {
    background-color: #E4F6E2 !important;
}
</style>
