<script setup>

import { ref, onBeforeMount } from "vue";
import AptDialog from "./appt-dialog.vue"
import useSchedule from "@/hooks/useSchedule.js";
import { useUserStore } from "@/stores/user";
import TodayStats from '@/components/schedule/todaysStats.vue';

const userStore = useUserStore();

let hours = [];

if (userStore.user.tenant.id == 2) {
    hours = [6, 7, 8, 9, 10, 11, 12, 13, 99, 14, 15, 16];
} else {
    hours = [88, 6, 7, 8, 9, 10, 11, 12, 13, 99, 14, 15, 16, 17];
}


let currentAppt = ref({});
let appts = ref({});
let doors = ref([]);

const { fetchDoors, fetchAppts } = useSchedule();

const props = defineProps({
    date: Object
});

const openAppt = (apt) => {
    showDialog.value = true;
    currentAppt.value = apt;
}

const closeAppt = () => {
    showDialog.value = false;
    currentAppt.value = {};
}

onBeforeMount(async () => {
    appts.value = await fetchAppts(props.date);
    doors.value = await fetchDoors();
});

const showDialog = ref(false);

</script>

<template>

    <AptDialog :doors="doors" :current-appt="currentAppt" :dialog="showDialog" :callback="closeAppt" v-if="showDialog" />

    <div class="schedule-grid">
        <table>
            <tbody>
                <template v-for="hour in hours">
                    <tr v-if="hour > 80" :class="{ 'count-0': !appts[hour] }">
                        <td class="time-cell text-center">
                            <div>
                                <h5 v-if="hour == 88">LTL 8 AM</h5>
                                <h5 v-if="hour == 99">LTL 2-4PM</h5>
                            </div>
                        </td>
                        <td class="appts-cell">
                            <div class="appts-wrapper ltl">
                                <div v-for="carrier in appts[hour]" class="appt">
                                    <div>
                                        <div class="title pending">
                                            <h5>
                                                <div>
                                                    <span>{{ carrier.name }}</span>
                                                </div>
                                            </h5>
                                            <small>
                                                <div>
                                                    {{ carrier.appts.length }} Order(s)
                                                </div>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="carrier-bottom">
                                        <div class="flex-grow">
                                            <table class="orders-table">
                                                <tbody>
                                                    <tr v-for="appt in carrier.appts" :class="appt.status_name">
                                                        <td class="px-2 py-1">
                                                            <a style="font-size: inherit;" href="javascript:false;"
                                                                @click="openAppt(appt)">
                                                                {{ appt.order.po_number }}</a>
                                                        </td>
                                                        <td class="px-1">{{ appt.order.total_pallets }}
                                                            <small>PLT</small>
                                                        </td>
                                                        <td class="px-1">
                                                            {{ appt.order.ship_state }},
                                                            {{ appt.order.ship_zip }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="pallets">
                                            <div>{{ carrier.pallet_count }}</div>
                                            <small>PLT</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-else :class="{ 'count-0': !appts[hour] }">
                        <td class="time-cell">
                            <div>
                                <h3>
                                    {{ hour }}:00
                                </h3>
                            </div>
                        </td>
                        <td class="appts-cell">
                            <div class="appts-wrapper">
                                <div v-for="appt in appts[hour]"
                                    :class="['appt', appt.order_type_id == 1 ? 'bg-light-green' : '']"
                                    @click="openAppt(appt)">
                                    <div>
                                        <div :class="['title', appt.status_name]">
                                            <h5>{{ appt.order.po_number }}</h5>
                                            <small>
                                                <div>
                                                    {{ appt.window_start.split('T')[1].split(':').slice(0, 2).join(':') }}
                                                </div>
                                                <div>
                                                    {{ appt.warehouse.nickname }}
                                                    <span v-if="appt?.door_id">- {{ appt.dock_door?.name }}</span>
                                                </div>
                                            </small>
                                        </div>
                                        <div class="client">
                                            <div>{{ appt.order.client_name }}</div>
                                            <small>{{ appt.order_type_id == 1 ? "Inbound" : "Outbound" }}</small>
                                        </div>
                                        <div class="carrier">
                                            <span v-if="appt.carrier">{{ appt.carrier.name
                                                }}</span>
                                            <span v-else>
                                                <span v-if="appt.order.carrier">{{ appt.order.carrier.name }}</span>
                                                <small v-else>Unknown Carrier</small>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="bottom">
                                        <div class="ship-to-address" v-if="appt.order.type_id == 2">
                                            <strong>SHIP TO</strong><br />
                                            {{ appt.order.ship_name }}<br />
                                            {{ appt.order.ship_state }}, {{ appt.order.ship_zip
                                            }}
                                        </div>
                                        <div class="ship-to-address" v-else>
                                            <template v-if="appt.order.container_number">
                                                <strong>CONTAINER #</strong><br />
                                                {{ appt.order.container_number }}<br />
                                            </template>
                                            <template v-else>
                                                <strong>PO #</strong><br />
                                                {{ appt.order.po_number }}<br />
                                            </template>
                                            <strong>FOR</strong><br />
                                            {{ appt.order?.client?.name }}<br />
                                        </div>
                                        <div class="pallets">
                                            <div class="plt-ct">{{ appt.order.total_pallets }}
                                            </div>
                                            <small>PLT&nbsp;</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
    <TodayStats :appts="appts" :key="appts"/>
</template>

<style scoped>
/************************************
** DARK
************************************/

table {
    border-collapse: separate;
    border-spacing: 0px;
    width: 100%;
    background: #2a2a3b;
    color: #f5f5f5;
}



.time-cell div {
    background: #2a2a3b;
    color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300;
}

.time-cell div * {
    font-size: 3rem;
    font-weight: 300;
    margin: 5px;
}


.count-0 .time-cell div * {
    color: #333333;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #f5f5f5;
}


.time-cell,
.appts-cell {
    padding: 0.1px;
    margin: 0;
    border: 1px solid white;
    color:black;
}

.time-cell {
    width: 200px;
    min-width: 187px;
}
.appts-wrapper {
    display: flex;
    width: calc(100vw - 300px);
    overflow-x: auto;
    min-height: 50px;
    background: #2a2a3b;
    color: #f5f5f5;
}

.appt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #919b9f;
    max-width: 300px;
    min-width: 300px;
    min-height: 200px;
    margin-right: 2px;
    font-size: 20px;
    cursor: pointer;
}

.appt .title {
    color: #5c5454;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
}

.appt .title h5 {
    font-size: 18px;
    background: #0067b1;
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #b3b3b3;
}

.appt .pending h5,
.orders-table tr.pending td {
    background: #1E293B;
}

.appt .active h5,
.orders-table tr.active td {
    background: #1976d2;
}

.appt .complete h5,
.orders-table tr.complete td {
    background: #4caf50;
}

.appt .late h5,
.orders-table tr.late td {
    background: #f44336;
}

.appt .title small {
    /* Appt time */
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    text-align: right;
    padding: 3px 5px;
    font-size: 16px;
}

.appt .client {
    display: flex;
    justify-content: space-between;
    padding: 0rem 0 0 0.5rem;
}

.appt .client div {
    /* client name */
    font-size: 65%;
}

.appt .client small {
    /* inbound / outbound */
    text-transform: uppercase;
    display: block;
    font-size: 60%;
    font-weight: 600;
    background: #ffffff;
    color: #333333;
    padding: 0 5px;
    height: 18px;
    border-radius: 3px 0 0 3px;
}



.appt .carrier {
    font-size: 80%;
    padding: 0rem 0.5rem;
}


.appt .carrier-bottom {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
}


.appt .bottom {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.appt .bottom *,
.appt .carrier-bottom * {
    font-size: 65%;
}

.appt .carrier-bottom .flex-grow {
    flex-grow: 1;
}

.appt .bottom .pallets,
.appt .carrier-bottom .pallets {
    padding: 0rem 0.65rem 0.65rem 0.65rem;
    background: rgba(255, 255, 255, 0.548);
    border-top-left-radius: 4px;
}

.appt .bottom .ship-to-address {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    overflow: hidden;
}

.appt .bottom .ship-to-address strong {
    padding: 0;
    font-size: 70%;
}

.appt .bottom .pallets,
.appt .carrier-bottom .pallets {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.appt .bottom .pallets div.plt-ct,
.appt .carrier-bottom .pallets div.plt-ct {
    font-size: 250%;
    height: 40px;
    text-align: right;
}

.appt .bottom .pallets small,
.appt .carrier-bottom .pallets small {
    font-size: 100%;
    text-align: right;
}



.appt .carrier-bottom table td,
.appt .carrier-bottom table {
    background: transparent;
    font-size: 14px;
}

.appt .carrier-bottom table {
    width: 100%;
}

.date {
    text-align: center;
    color: #333333;
}

.schedule-grid {
    height: calc(100vh - 60px);
    overflow: auto;
    font-size: 12px;
}

.table thead tr th {
    border-bottom-width: 0;
}

.table tbody tr td {
    border-bottom: 1px solid #4f4f4f;
}


/* General */

.table-fixed-head {
    overflow: auto;
    height: calc(100vh - 60px);
}

.table-fixed-head table thead th {
    position: sticky;
    top: 0;
}

.time-picker {
    font-size: 20px;
}

.v-dialog {
    z-index: -999999;
}

[v-cloak] {
    display: none !important;
}

[v-cloak]::before {
    content: "Loading...";
}

.upcoming-header {
    display: flex;
    justify-content: flex-start;
    background: #efefef;
    border-bottom: #b3b3b3 0px solid;
    width: calc(100vw - 65px);
    min-height: 75px;
    overflow-x: auto;
    overflow-y: hidden;
}

.upcoming-header .date {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: 75px;
    min-width: 100px;
    border-right: #b3b3b3 1px solid;
    justify-content: space-between;
    cursor: pointer;
}

.upcoming-header .date.active {
    background-color: #adadade1;
}

.upcoming-header .counts {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: 300;
}

.upcoming-header .date .d {
    display: flex;
    justify-content: space-between;
    font-variant: small-caps;
}

.upcoming-header .date .ct {
    text-align: left;
}

.upcoming-header .date .plts {
    text-align: right;
}


.orders-table tr.pending td {
    background: #9e9e9e;
}

.orders-table tr.active td {
    background: #1976d2;
}

.orders-table tr.complete td {
    background: #4caf50;
}

.orders-table tr.late td {
    background: #f44336;
}

.orders-table tr td a {
    color: #333333;
}

.ltl .appt {
    max-width: 555px;
}

.bg-light-green {
    background-color: #E4F6E2 !important;
}
</style>