<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import {
    saveGridState,
    loadGridState,
    connectToServerApi
} from "@/utils/agGridUtils";

import { getDefaultColDefs, getDefaultGridOptions } from '../../utils/aggridCommonConfigs.js';
import status from '../../components/schedule/statusRenderer.vue';
import { useThemeStore } from "@/stores/themeStore";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const OUTBOUND_STAGED_URL = "/outbound_orders/staged";
const GRID_SAVE_KEY = "outbound_staged_grid_state";
const CONNECT_BASE_URL = import.meta.env.VITE_CONNECT_URL;

let gridApi;
let context = ref(null);

const advancedFilterKeys = ["id", "warehouse.name", "po_number", "ship_zip", "pickup_date", "total_cases", "total_pallets", "order_status.name", "notes"];

const onGridReady = (params) => {
    gridApi = params.api;
    context.value = {
        apiUrl: OUTBOUND_STAGED_URL,
        advancedFilterKeys: advancedFilterKeys,
    };
    loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
    gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const onStateUpdated = function (params) {
    saveGridState(GRID_SAVE_KEY, gridApi);
};

const defaultColDef = getDefaultColDefs();

const LinkCellRenderer = (params) => {
    return (
        '<a href=' + CONNECT_BASE_URL + '/outbound/' + params.data.id + ' target = "_blank" rel = "noopener noreferrer" > ' +
        params.data.po_number +
        '</a>'
    );
};

const columnDefs = [
    { field: "id", headerName: "#", colId: "id", cellDataType: "text", filter:false },
    { field: "warehouse.name", headerName: "Warehouse", colId: "warehouse.name", cellDataType: "text", filter:false },
    { field: "po_number", headerName: "PO Number", colId: "po_number", cellRenderer: LinkCellRenderer, width: "350", filter:false },
    {
        headerName: "Zip Code", colId: "ship_zip", cellDataType: "text", filter:false, valueGetter: (params) => {
            return params.data.ship_state + " , " + params.data.ship_zip;
        }
    },
    { field: "pickup_date", headerName: "Pickup On", colId: "prep_on", cellDataType: "text", filter:false },
    { field: "total_cases", headerName: "Total Cases", colId: "total_pieces", cellDataType: "text", filter:false },
    { field: "total_pallets", headerName: "Total Pallets", colId: "", cellDataType: "text", filter:false },
    {
        field: "order_status.name", headerName: "Status", colId: "order_status.name", cellRenderer: status, filter:false, cellRendererParams: (params) => {

            let color = "grey";
            let status = params.value;
            if (status === 'Staged') {
                color = "blue";
            } else if (status === 'Ready To Ship') {
                color = "green";
            }
            return {
                color
            };
        }
    },
    { field: "notes", headerName: "Notes", colId: "Notes", cellDataType: "text", filter:false },
];

const gridOptions = getDefaultGridOptions(columnDefs);

</script>

<template>

    <div class="pa-4">
        <AgGridVue style="width: 100%; height: 800px;" :class="themeStore.agTheme" :columnDefs="columnDefs"
            :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady" :context="context"
            @state-updated="onStateUpdated"></AgGridVue>
    </div>
</template>