import { ref } from "vue";
import c3api from "../c3api";
import { useToast } from "vue-toastification";

export default function useSchedule() {
  const apptsLoading = ref(0);
  const appts = ref([]);
  const toast = useToast();

  const restructureResponse = (data) => {
    // Extract the hour from the window_start timestamp
    const groupedByHour = data
      .filter((curr) => curr.pm_ltl === false)
      .reduce((acc, curr) => {
        // Extract the hour from the window_start timestamp
        const hour = new Date(curr.window_start).getUTCHours();

        // Initialize the array if the hour doesn't exist
        if (!acc[hour]) {
          acc[hour] = [];
        }

        // Add the current item to the appropriate hour array
        acc[hour].push(curr);

        return acc;
      }, {});

    const groupByCarriers = data
      .filter((curr) => curr.pm_ltl === true && curr.carrier.id)
      .reduce((acc, curr) => {
        const carrierId = curr.carrier?.id;

        if (!acc[carrierId]) {
          acc[carrierId] = {};
          acc[carrierId].appts = [];
          acc[carrierId].pallet_count = 0;
        }

        acc[carrierId].appts.push(curr);
        acc[carrierId].pallet_count += curr.pallet_count;
        acc[carrierId].name = curr.carrier.name;

        return acc;
      }, {});

    const final = {
      99: groupByCarriers,
    };

    return { ...groupedByHour, ...final };
  };

  const fetchAppts = async (date) => {
    apptsLoading.value++;

    let savedExternalFilters = window.localStorage.getItem(
      "schedule-external-filter",
    );
    let filters = JSON.parse(savedExternalFilters);
    let warehouseIds = filters.warehouseIds;

    try {
      const params = {
        date: date,
        "warehouse_id[]": warehouseIds,
      };

      const response = await c3api.get(`/appts/schedule_block`, {
        params: params,
      });
      const responseFinal = restructureResponse(response.data.data);
      return (appts.value = responseFinal);
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      apptsLoading.value--;
    }
  };

  const fetchDoors = async () => {
    apptsLoading.value++;

    let savedExternalFilters = window.localStorage.getItem(
      "schedule-external-filter",
    );
    let filters = JSON.parse(savedExternalFilters);
    let warehouseIds = filters.warehouseIds;

    try {
      const params = new URLSearchParams();

      warehouseIds.forEach((id) =>
        params.append("filter[warehouse_id][filter][]", id),
      );
      warehouseIds.forEach(() =>
        params.append("filter[warehouse_id][type]", "equals"),
      );

      const response = await c3api.get(`/dock_doors`, {
        params: params,
      });
      return (appts.value = response.data.data);
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      apptsLoading.value--;
    }
  };

  const updateAppt = async (id, data) => {
    try {
      apptsLoading.value++;
      const response = await c3api.patch(`/appts/${id}`, data);
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      apptsLoading.value--;
    }
  };

  return {
    fetchDoors,
    updateAppt,
    apptsLoading,
    appts,
    fetchAppts,
  };
}
