<script setup>

import {computed} from 'vue';

const props = defineProps({
    appts: Object,
});

// Define computed property for totals
const totals = computed(() => {
  let apptsCount = 0;
  let pallets = 0;
  let inPallets = 0;
  let outPallets = 0;
  let statuses = {};

  Object.keys(props.appts).forEach(key => {
    if (key > 80) {
      Object.keys(props.appts[key]).forEach(k => {
        apptsCount++;
        const carrier = props.appts[key][k];
        pallets += carrier.pallet_count;
        if (carrier.appts[0].order.type_id === 1) {
          inPallets += carrier.pallet_count;
        } else if (carrier.appts[0].order.type_id === 2) {
          outPallets += carrier.pallet_count;
        }
      });
    } else {
      apptsCount += props.appts[key].length;
      props.appts[key].forEach(appt => {
        if (!statuses[appt.status_name]) {
          statuses[appt.status_name] = 0;
        }
        statuses[appt.status_name]++;
        pallets += appt.order.total_pallets;
        if (appt.order_type_id === 1) {
          inPallets += appt.order.total_pallets;
        } else if (appt.order_type_id === 2) {
          outPallets += appt.order.total_pallets;
        }
      });
    }
    statuses["remaining"] = apptsCount - (statuses["complete"] || 0);
  });

  return {
    appts: apptsCount,
    pallets,
    inPallets,
    outPallets,
    statuses
  };
});

</script>

<template>
  <div class="stats">
    <div class="stats-item stats-item-title">
      <div>Today's Stats</div>
    </div>
    <div class="stats-item">
      <div class="bar"></div>
      <div>Appointments</div>
      <small>{{ totals.appts }}</small>
    </div>
    <div class="stats-item">
      <div class="bar"></div>
      <div>Pallets</div>
      <small>{{ totals.pallets }}</small>
    </div>
    <div class="stats-item">
      <div class="bar"></div>
      <div>In Pallets</div>
      <small>{{ totals.inPallets }}</small>
    </div>
    <div class="stats-item">
      <div class="bar"></div>
      <div>Out Pallets</div>
      <small>{{ totals.outPallets }}</small>
    </div>
    <div class="stats-item">
      <div class="bar active"></div>
      <div>Active</div>
      <small>{{ totals.statuses["active"] || 0 }}</small>
    </div>
    <div class="stats-item">
      <div class="bar late"></div>
      <div>Late</div>
      <small>{{ totals.statuses["late"] || 0 }}</small>
    </div>
    <div class="stats-item">
      <div class="bar pending"></div>
      <div>Remaining</div>
      <small>{{ totals.statuses["remaining"] || 0 }}</small>
    </div>
    <div class="stats-item">
      <div class="bar complete"></div>
      <div>Complete</div>
      <small>{{ totals.statuses["complete"] || 0 }}</small>
    </div>
  </div>
</template>

<style scoped>
.stats {
  overflow: auto;
  width: 300px;
  border-left: 5px solid #666666;
  background: #2a2a3b;
  float: right;
}

.stats-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #1E293B;
  height: 80px;
}

.stats-item div:not(.bar) {
  width: 100%;
  font-size: 135%;
  color: #f5f5f5;
  font-weight: 300;
  padding: 1rem;
  overflow: hidden;
}

.stats-item small {
  text-align: right;
  width: 100px;
  font-size: 150%;
  color: #f5f5f5;
  padding: 1rem;
}

.stats-item div.bar {
  width: 15px;
  height: 80px;
}

.stats-item .pending {
  background: #9e9e9e;
}

.stats-item .active {
  background: #1976d2;
}

.stats-item .complete {
  background: #4caf50;
}

.stats-item .late {
  background: #f44336;
}

.orders-table tr td a {
  color: #2a2a3b;
}
</style>